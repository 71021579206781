// Container for individual question cards
import React, { Component } from "react";
import nextId from "react-id-generator";
import QuestionCard from "./question_card";
import simulateQuestion from "./../survey-utilities";

class Survey extends Component {
  state = {
    next_min_choices: 1,
    next_max_choices: 1,
    next_number_choices: 1,
    number_participants: 0,
    entries: [
      { id: nextId(), max_choices: 1, min_choices: 1, answers: [0, 0] },
    ],
  };

  fillInAnswers = () => {
    const list = this.state.entries.map((item) => {
      return {
        ...item,
        choices: 2,
        answers: simulateQuestion(
          item.answers,
          item.max_choices,
          item.min_choices,
          this.state.number_participants
        ),
      };
    });

    this.setState({ entries: list });
  };

  newEntry = () => {
    var max = parseInt(this.state.next_max_choices);
    var min = parseInt(this.state.next_min_choices);

    if (max < min) {
      var tmp = min;
      min = max;
      max = tmp;
    }

    var newQuestion = {
      id: nextId(),
      max_choices: max,
      min_choices: min,
      answers: Array(parseInt(this.state.next_number_choices, 10)).fill(0),
    };
    console.log(newQuestion);
    var newList = [...this.state.entries, newQuestion];
    this.setState({ entries: newList });
  };

  deleteEntry = (id) => {
    var newArray = this.state.entries.filter((e) => e.id !== id);
    this.setState({ entries: newArray });
  };

  render() {
    const { entries } = this.state;
    return (
      <div id="app-container">
        <div id="header">
          <p
            className={
              this.state.min_choices > this.state.max_choices ? ".red-text" : ""
            }
          >
            Min. choices
          </p>
          <input
            defaultValue="1"
            type="number"
            onChange={(e) =>
              this.setState({
                next_min_choices: e.target.value,
              })
            }
          ></input>
          <p>Max. choices</p>
          <input
            defaultValue="1"
            type="number"
            onChange={(e) =>
              this.setState({ next_max_choices: e.target.value })
            }
          ></input>
          <p>Possible choices</p>
          <input
            defaultValue="1"
            type="number"
            onChange={(e) =>
              this.setState({ next_number_choices: e.target.value })
            }
          ></input>
          <button onClick={this.newEntry}>Create</button>
        </div>
        <div className="question-box">
          {entries.map((entry, index) => {
            return (
              <QuestionCard
                index={index + 1}
                key={entry.id}
                question={entry}
                deleteSelf={(id) => this.deleteEntry(id)}
              />
            );
          })}
          <button type="button" onClick={this.fillInAnswers}>
            Simulate answers.
          </button>
          <input
            type="text"
            onChange={(e) =>
              this.setState({ number_participants: e.target.value })
            }
          ></input>
        </div>
      </div>
    );
  }
}

export default Survey;
