export default function simulateQuestion(
  given_answers,
  max_choices,
  min_choices,
  n
) {
  const answers = [...given_answers];
  var possible_choices = answers.length;
  if (max_choices > possible_choices) max_choices = possible_choices;

  var numberAnswers = randomIntFromInterval(min_choices, max_choices);
  console.log(
    n +
      " times. " +
      "chosen # answers. min:" +
      min_choices +
      "max:" +
      max_choices +
      " -> " +
      numberAnswers
  );

  if (numberAnswers > possible_choices)
    throw new RangeError("More answers than choices");

  while (n--) {
    var len = answers.length;
    var m = numberAnswers;
    var taken = new Array(len);
    while (m--) {
      var x = Math.floor(Math.random() * possible_choices);
      answers[x in taken ? taken[x] : x]++;
      taken[x] = --len in taken ? taken[len] : len;
    }
  }
  return answers;
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
