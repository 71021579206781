import React from "react";
import { IoMdClose } from "react-icons/io";
const QuestionCard = ({ index, question, deleteSelf }) => {
  return (
    <div className="card">
      <h3>{index}</h3>
      <p>{question.min_choices + " - " + question.max_choices}</p>
      {question.answers.map((count, index) => {
        return <p key={index}>{count}</p>;
      })}
      <IoMdClose onClick={() => deleteSelf(question.id)} />
    </div>
  );
};

export default QuestionCard;
