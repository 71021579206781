import React from "react";
import Survey from "./components/survey";

function App() {
  return (
    <div className="App">
      <Survey></Survey>
    </div>
  );
}

export default App;
